import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import SampleForm from '../components/Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Dan from '../img/headshot-dan.jpeg'

class IndexPage extends React.Component {
    render() {
        const siteTitle = this.props.data.site.siteMetadata.title
        const siteDescription = this.props.data.site.siteMetadata.description

        return (
            <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div>
          <div class="home-atf">
            <div class="container" id="atf-h1">
              <h1 id="atf" class="h1">
                UTE VALLEY APPRAISALS
              </h1>
              <p class="atf-subheading">
                Certified Residential Home Appraiser specializing in Colorado
                Springs, El Paso County, Teller County, and Elbert County.
              </p>
              <a class="btn btn-primary atf" href="#contactarea">
                GET STARTED TODAY
              </a>
            </div>
          </div>
        </div>
        <div id="services">
          <div class="services">
            <h2 id="aboutus-h1">Appraisals</h2>
            <p class="atf-subheading appraisal">
              <br />
            </p>
            <div class="row">
              <div class="col-md-4 p services real-estate">
                <h2 id="h1-appraisal">
                  <strong>Residential&nbsp;</strong>
                  <br />
                  <strong>Real Estate</strong>
                </h2>
                <p>
                  Providing residential appraisals for single family residences,
                  rental properties, condos, townhomes, duplexes
                  <br />
                  <br />
                  <br />
                </p>
                <a class="btn btn-primary atf" href="#contactarea">
                  Get Quote
                </a>
              </div>
              <div class="col-md-4 p services vacant-land">
                <h2 id="h1-appraisal">
                  <strong>Vacant </strong>
                  <br />
                  <strong>Land</strong>
                  <br />
                </h2>
                <p>
                  Appraisal of vacant land ranging from less than an acre to
                  rural acreages of hundreds of acres
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
                <a class="btn btn-primary atf" href="#contactarea">
                  Get Quote
                </a>
              </div>
              <div class="col-md-4 p services new-const">
                <h2 id="h1-appraisal">
                  <strong>New </strong>
                  <br />
                  <strong>Construction</strong>
                  <br />
                </h2>
                <p>
                  Appraisal for residential new construction projects
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
                <a class="btn btn-primary atf" href="#contactarea">
                  Get Quote
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="about" class="home-aboutus">
          <div class="row">
            <div id="about-h1">
              <h2 id="aboutme-h1">ABOUT ME</h2>
              <img class="headshot-about" src={Dan} />
              <p id="p-about-us">
                Dan Klausmeier is a Certified Residential Appraiser,
                CR200001811, located in Colorado with competency in Colorado
                Springs, eastern El Paso county, Elbert county, and Teller
                county. Areas of competency include 1004URAR, HUD Manufactured,
                modular, construction loans, condominiums, townhomes, rural
                homes on acreage and vacant land. Experience in FHA,
                Conventional and construction loan appraisals using all
                approaches to value as necessary. <br />
                <br />
                Dan is a graduate of Doherty High School and a long-time
                Colorado Springs resident. He enjoys spending time with his two
                young children and wife exploring the mountains of Colorado and
                riding his mountain bike. He looks forward to using his
                expertise of the community and appraisal experience to provide
                an accurate appraisal of value.{' '}
              </p>
              <a class="btn btn-primary atf" href="#contactarea">
                CONTACT US
              </a>
            </div>
            <div class="col-sm-12 col-md-6 badges" />
          </div>
        </div>
        <div id="contactarea" class="contact-clean">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h2 class="h1 contact">
                  <br />
                  REQUEST MORE INFORMATION
                </h2>
                <ul class="contact-info">
                  <li class="contact-details">
                    <div class="contact-icon">
                      <i class="fa fa-location-arrow" />
                    </div>
                    <div class="contact-text">
                      <span>
                        <strong>ADDRESS</strong>
                      </span>
                      <p>
                        955 War Eagle Dr N,&nbsp;Colorado Springs, CO
                        80919&nbsp;
                      </p>
                    </div>
                  </li>
                </ul>
                <ul class="contact-info">
                  <li class="contact-details">
                    <div class="contact-icon">
                      <i class="fa fa-phone" />
                    </div>
                    <div class="contact-text">
                      <span>
                        <strong>CONTACT</strong>
                      </span>
                      <p>719-257-3302</p>
                    </div>
                  </li>
                </ul>
                <ul class="contact-info">
                  <li class="contact-details">
                    <div class="contact-icon">
                      <i class="fa fa-envelope" />
                    </div>
                    <div class="contact-text">
                      <span>
                        <strong>EMAIL</strong>
                      </span>
                      <p>utevalleyappraisals@gmail.com</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-6">
                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5d775409adee1b46712e6371"
                  form_classname="appraisal-form"
                >
                  <h2 class="text-center">APPRAISAL FORM</h2>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        name="Email Address"
                        id="email_address"
                      />
                      <label htmlFor="email_address">
                        Enter Your Email Address
                      </label>
                    </div>
                    <small className="text-dark form-text mt-2">
                      We will never share your email with anyone else.
                    </small>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Name"
                        id="full_name"
                      />
                      <label htmlFor="full_name">Enter Your Name</label>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="Phone"
                        id="phone"
                      />
                      <label htmlFor="phone">Enter Your Contact Number</label>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        type="textarea"
                        name="Message"
                        id="message"
                      />
                      <label htmlFor="message">
                        Enter Property Details Here
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="webriq-recaptcha" />
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        className="btn btn-primary contactform"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </SampleForm>
              </div>
            </div>
          </div>
        </div>
      </Layout>
        )
    }
}

export default IndexPage

export const indexPageQuery = graphql `
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`